import React from "react";
import { FormattedMessage } from "react-intl";
import Input from "app/pages/.shared/form/Input.jsx";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./identity-fieldset.scss";
import PropTypes from "prop-types";
import { CIVILITY } from "app/constants";
import Select from "app/pages/.shared/form/Select";

class IdentityFieldset extends React.PureComponent {
	render() {
		const { fields = {}, id, onBlur, isRequired = false } = this.props;

		return (
			<div className="identity">
				<div className="identity__gender identity__item identity__radio">
					<Select
						isRequired={isRequired}
						name="honorific-prefix"
						id={`${id}-gender`}
						field={fields.gender}
						label={<FormattedMessage id="identity.gender.label" />}
						onChange={onBlur}
						data-cy="gender-select-input"
					>
						<option />
						{[CIVILITY.MALE, CIVILITY.FEMALE].map(gender => (
							<FormattedMessage
								key={gender}
								id={`general.gender.${gender.toLowerCase()}`}
							>
								{message => (
									<option key={gender} value={gender}>
										{message}
									</option>
								)}
							</FormattedMessage>
						))}
					</Select>
				</div>

				<div className="identity__lastname identity__item identity__input">
					<Input
						field={fields.lastName}
						isRequired={isRequired}
						label={<FormattedMessage id="identity.lastname.label.full" />}
						name="family-name"
						id={`${id}-lastname`}
						maxLength="30"
						onBlur={onBlur}
						data-cy="family-name-input"
					/>
				</div>

				<div className="identity__firstname identity__item identity__input">
					<Input
						field={fields.firstName}
						isRequired={isRequired}
						label={<FormattedMessage id="identity.firstname.label" />}
						name="given-name"
						id={`${id}-firstname`}
						maxLength="30"
						onBlur={onBlur}
						data-cy="given-name-input"
					/>
				</div>
			</div>
		);
	}
}

IdentityFieldset.propTypes = {
	id: PropTypes.string,
	fields: PropTypes.object,
	onBlur: PropTypes.func,
	isRequired: PropTypes.bool,
};

export default registerStyle(style)(IdentityFieldset);
