import PropTypes from "prop-types";
import React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import range from "lodash/range";
import { PASSENGER_TYPE } from "app/constants";
import Select from "app/pages/.shared/form/Select.jsx";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./birthdate-input.scss";

const BirthdateInput = ({ fields = {}, id, type = PASSENGER_TYPE.ADULT }) => {
	const thisYear = new Date().getFullYear();

	let maxYear = thisYear - 12;
	let minYear = 1900;

	if (type === PASSENGER_TYPE.CHILD) {
		// enfant entre 2 et 11 ans
		maxYear = thisYear - 3;
		minYear = thisYear - 12;
	} else if (type === PASSENGER_TYPE.INFANT) {
		// enfant entre 0 et 2 ans
		maxYear = thisYear;
		minYear = thisYear - 3;
	}

	return (
		<div className="birthdate">
			<div className="birthdate__day birthdate__item birthdate__select">
				<Select
					id={`birthdate-day-${id}`}
					name={`birthdate-day-${id}`}
					field={fields.day}
					label={<FormattedMessage id="profile.birthdate.day.label" />}
					data-cy="birthdate-day-select-input"
				>
					<option />
					{range(1, 32).map(day => (
						<option key={day} value={day}>
							{day}
						</option>
					))}
				</Select>
			</div>

			<div className="birthdate__month birthdate__item birthdate__select">
				<Select
					id={`birthdate-month-${id}`}
					name={`birthdate-month-${id}`}
					field={fields.month}
					label={<FormattedMessage id="profile.birthdate.month.label" />}
					data-cy="birthdate-month-select-input"
				>
					<option />
					{range(1, 13).map(month => {
						/* utilisation d'un object Date pour fix erreur traduction dans firefox et safari */
						return (
							<FormattedDate
								key={month}
								value={new Date(thisYear, month - 1, 1)}
								month="long"
							>
								{monthLabel => <option value={month}>{monthLabel}</option>}
							</FormattedDate>
						);
					})}
				</Select>
			</div>

			<div className="birthdate__year birthdate__item birthdate__select">
				<Select
					id={`birthdate-year-${id}`}
					name={`birthdate-year-${id}`}
					field={fields.year}
					label={<FormattedMessage id="profile.birthdate.year.label" />}
					data-cy="birthdate-year-select-input"
				>
					<option />
					{range(maxYear, minYear).map(year => (
						<option key={year} value={year}>
							{year}
						</option>
					))}
				</Select>
			</div>
		</div>
	);
};

BirthdateInput.propTypes = {
	id: PropTypes.string.isRequired,
	fields: PropTypes.object,
	type: PropTypes.oneOf([PASSENGER_TYPE.CHILD, PASSENGER_TYPE.INFANT, PASSENGER_TYPE.ADULT]),
};

export default registerStyle(style)(BirthdateInput);
