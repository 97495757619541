import React from "react";
import { injectIntl, intlShape } from "react-intl";
import injectCountries from "app/utils/intl/InjectCountries";
import Select from "app/pages/.shared/form/Select.jsx";

import { registerStyle } from "app/utils/CriticalCSS";
import style from "./nationality.scss";
import PropTypes from "prop-types";

const Nationality = ({ nationality, id, intl, countries = [] }) => (
	<div className="nationality">
		<Select
			id={`nationality-${id}`}
			name="nationality"
			field={nationality}
			label={intl.formatMessage({
				id: "nationality.label",
				description: "Nationality label",
				defaultMessage: "Nationality",
			})}
		>
			<option />
			{countries.map(country => (
				<option key={country.cca2} value={country.cca2}>
					{country.name}
				</option>
			))}
		</Select>
	</div>
);

Nationality.propTypes = {
	nationality: PropTypes.object,
	id: PropTypes.string,
	intl: intlShape,
	countries: PropTypes.arrayOf(PropTypes.object),
};

export default registerStyle(style)(injectIntl(injectCountries(Nationality)));
