import React from "react";
import { FormattedMessage } from "react-intl";
import Input from "app/pages/.shared/form/Input";
import "./Phone.scss";
import PropTypes from "prop-types";

const Phone = ({ phone, isRequired = false, onBlur, ...restProps }) => (
	<div className="phone">
		<Input
			type="tel"
			isRequired={isRequired}
			field={phone}
			label={<FormattedMessage id="phone.label" />}
			name="tel-national"
			id="phone"
			maxLength="50"
			onBlur={onBlur}
			data-cy={restProps["data-cy"]}
		/>
	</div>
);

Phone.propTypes = {
	phone: PropTypes.object,
	isRequired: PropTypes.bool,
	onBlur: PropTypes.func,
};

export default Phone;
