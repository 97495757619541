import React from "react";
import { FormattedMessage } from "react-intl";
import {
	sendTagOnGoogleAutoCompleteError,
	sendTagOnGoogleAutocompleteSelect,
} from "app/utils/analytics";
import AddressAutocomplete from "app/pages/.shared/form/AddressAutocomplete";
import injectCountries from "app/utils/intl/InjectCountries";
import Input from "app/pages/.shared/form/Input.jsx";
import Select from "app/pages/.shared/form/Select.jsx";
import { registerStyle } from "app/utils/CriticalCSS";
import style from "./address-fieldset.scss";
import PropTypes from "prop-types";

class AddressFieldset extends React.PureComponent {
	constructor() {
		super();
		this.state = {
			loadingAdresseDetail: false,
		};
		this.handleAddressSelection = this.handleAddressSelection.bind(this);
		this.handleBeforeAddressSelection = this.handleBeforeAddressSelection.bind(this);
		this.handleCountryChange = this.handleCountryChange.bind(this);
	}

	handleCountryChange(event) {
		if (typeof this.props.onCountryChange === "function") {
			this.props.onCountryChange(event);
		}

		if (typeof this.props.onBlur === "function") {
			this.props.onBlur(event);
		}
	}

	handleBeforeAddressSelection() {
		this.setState({
			loadingAdresseDetail: true,
		});
	}

	handleAddressSelection(place) {
		const { fields = {}, countries = [] } = this.props;

		this.setState(
			{
				loadingAdresseDetail: false,
			},
			() => {
				fields.line1.onChange(place.name);
				fields.line2.onChange(undefined);
				fields.postCode.onChange(place.components.postalCode);
				fields.city.onChange(place.components.city);
				const countryCode = countries.find(
					country => country.name === place.components.country
				);
				if (countryCode) {
					fields.country.onChange(countryCode.cca2);
				}
				sendTagOnGoogleAutocompleteSelect(place.formattedAddress);
			}
		);
	}

	handleAutocompleteError(type, status) {
		sendTagOnGoogleAutoCompleteError(type, status);
	}

	render() {
		const { fields, id, countries, onBlur, isRequired } = this.props;

		return (
			<div className="address">
				<div className="address__line1 address__input address__item">
					<AddressAutocomplete
						label={<FormattedMessage id="address.address1.label" />}
						isRequired={isRequired}
						id={`${id}-address-line1`}
						field={fields.line1}
						name="address-line1"
						onBlur={onBlur}
						onError={this.handleAutocompleteError}
						onPlaceSelect={this.handleAddressSelection}
						onBeforePlaceSelect={this.handleBeforeAddressSelection}
						data-cy="address-line1-input"
					/>
				</div>

				<div className="address__line2 address__input address__item">
					<Input
						field={fields.line2}
						label={<FormattedMessage id="address.address2.label" />}
						name="address-line2"
						id={`${id}-address-line2`}
						onBlur={onBlur}
						data-cy="address-line2-input"
					/>
				</div>
				<div className="address__row">
					<div className="address__postcode address__input address__item">
						<Input
							isRequired={isRequired}
							loading={this.state.loadingAdresseDetail}
							field={fields.postCode}
							label={<FormattedMessage id="address.postCode.label" />}
							name="postal-code"
							id={`${id}-address-postCode`}
							maxLength="12"
							onBlur={onBlur}
							data-cy="postal-code-input"
						/>
					</div>

					<div className="address__city address__input address__item">
						<Input
							isRequired={isRequired}
							loading={this.state.loadingAdresseDetail}
							field={fields.city}
							label={<FormattedMessage id="address.city.label" />}
							name="address-level2"
							id={`${id}-address-city`}
							maxLength="50"
							onBlur={onBlur}
							data-cy="city-input"
						/>
					</div>

					<div className="address__country address__select address__item">
						<Select
							isRequired={isRequired}
							loading={this.state.loadingAdresseDetail}
							id={`${id}-address-country`}
							name="country-name"
							label={<FormattedMessage id="address.country.label" />}
							field={fields.country}
							onChange={this.handleCountryChange}
							data-cy="country-select-input"
						>
							<option />
							{countries.map(country => (
								<option key={country.cca2} value={country.cca2}>
									{country.name}
								</option>
							))}
						</Select>
					</div>
				</div>
			</div>
		);
	}
}

AddressFieldset.defaultProps = {
	fields: {},
	countries: [],
	isRequired: false,
};

AddressFieldset.propTypes = {
	fields: PropTypes.object,
	countries: PropTypes.arrayOf(PropTypes.object),
	onCountryChange: PropTypes.func,
	onBlur: PropTypes.func,
	id: PropTypes.string,
	isRequired: PropTypes.bool,
};

export default registerStyle(style)(injectCountries(AddressFieldset));
