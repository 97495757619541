import React from "react";
import { FormattedMessage } from "react-intl";
import Input from "app/pages/.shared/form/Input.jsx";

import { registerStyle } from "app/utils/CriticalCSS";
import style from "./email.scss";
import PropTypes from "prop-types";

const Email = ({ email, disabled, onBlur }) => (
	<div className="email">
		<Input
			type="email"
			field={email}
			label={<FormattedMessage id="email.label" />}
			name="email"
			id="email"
			maxLength="100"
			disabled={disabled}
			onBlur={onBlur}
			data-cy="email-input"
		/>
	</div>
);

Email.propTypes = { email: PropTypes.object, disabled: PropTypes.bool, onBlur: PropTypes.func };

export default registerStyle(style)(Email);
